<template>
  <div>
    <div id="tender-page" v-if="tender">
      <div class="flex-row">
        <h1 class="h1-title">{{ tender.title }}</h1>
        <secondary-button
          title="Edit"
          class="margin-left-auto vertical-flex-center"
          :disabled="tenderHasStarted(tender)"
          v-on:click="presentTenderModal()"
        />
        <secondary-button
          title="Download"
          icon="/assets/img/icon-download.svg"
          class="margin-left-05 margin-right vertical-flex-center"
          v-on:click="getCSV()"
        />
      </div>
      <p class="time-label">Start Time: {{ formatDate(tender.start_time) }}</p>
      <p class="time-label">End Time: {{ formatDate(tender.end_time) }}</p>
      <div class="flex-row">
        <p class="time-label">Above/Below Reserve (Traded):</p>
        <div
          class="flex-row margin-left-05"
          :class="{
            'up-on-reserve': calculateReserve() > 0,
            'down-on-reserve': calculateReserve() < 0,
            'time-label': calculateReserve() == 0
          }"
        >
          <p v-if="calculateReserve() > 0">+$</p>
          <p v-else-if="calculateReserve() < 0">- $</p>
          <p v-else>—</p>
          <p v-if="calculateReserve() != 0">{{ Math.abs(calculateReserve()) }}</p>
        </div>
      </div>
      <div class="flex-row">
        <p class="time-label">Above/Below Reserve (Theoretical):</p>
        <div
          class="flex-row margin-left-05"
          :class="{
            'up-on-reserve': calculateTheoreticalReserve() > 0,
            'down-on-reserve': calculateTheoreticalReserve() < 0,
            'time-label': calculateTheoreticalReserve() == 0
          }"
        >
          <p v-if="calculateTheoreticalReserve() > 0">+$</p>
          <p v-else-if="calculateTheoreticalReserve() < 0">- $</p>
          <p v-else>—</p>
          <p v-if="calculateTheoreticalReserve() != 0">{{ Math.abs(calculateTheoreticalReserve()) }}</p>
        </div>
      </div>
      <div class="flex-row margin-top-05">
        <tiny-button
          title="Traded"
          class="vertical-flex-center"
          :selected="theoreticalMode == 0"
          v-on:click="theoreticalMode = 0"
        />
        <tiny-button
          title="Theoretical"
          class="margin-left-05 vertical-flex-center"
          :selected="theoreticalMode == 1"
          v-on:click="theoreticalMode = 1"
        />
        <tiny-button
          title="Standard"
          class="margin-left-05 vertical-flex-center"
          :selected="theoreticalMode == 2"
          v-on:click="theoreticalMode = 2"
        />
      </div>
      <results-table
        class="margin-top"
        :availableSearchMode="currentSearchMode()"
        :showPaginatorBar="false"
        :showAllForms="true"
        ref="resultsTable"
      />

      <tender-modal v-if="showTenderModal" :tender="tender" @cancel="showTenderModal = false" @submit="updateTender" />
    </div>
  </div>
</template>

<script>
import { fetchTender, updateTender } from "../../../api/Tender.js";
import { mapActions } from "vuex";

import { tenderHasStarted, tenderHasEnded } from "../../../helpers/formHelpers.js";

import ResultsTable from "../../../components/Views/v2/ResultsTable.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import TinyButton from "../../../components/Buttons/v2/TinyButton.vue";
import TenderModal from "../../../components/Modals/v2/CreateTenderModal.vue";

export default {
  name: "Tender",
  components: { ResultsTable, SecondaryButton, TinyButton, TenderModal },
  data: function () {
    return {
      tender: null,
      theoreticalMode: 0,
      showTenderModal: false,
      downloading: false
    };
  },
  methods: {
    currentSearchMode: function () {
      if (this.theoreticalMode == 2) {
        return "tender_meets";
      } else if (this.theoreticalMode == 1) {
        return "tender_theoretical";
      } else {
        return "tender";
      }
    },
    csvURL: function () {
      return this.tender.url + "download-csv/";
    },
    fetchTender: function () {
      fetchTender(this.$route.params.id)
        .then(response => {
          this.tender = response;
          this.setForms(response.forms);
          this.theoreticalMode = this.tenderHasEnded(this.tender) ? 0 : 1;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    presentTenderModal: function () {
      if (this.tenderHasStarted(this.tender)) {
        return;
      }
      this.showTenderModal = true;
    },
    updateTender: function (tenderDict) {
      updateTender(this.tender.id, tenderDict)
        .then(response => {
          this.tender = response;
          this.setForms(response.forms);
          this.showTenderModal = false;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    getCSV: function () {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.downloadCSV(this.csvURL()).then(() => {
        this.downloading = false;
      });
    },
    calculateReserve: function () {
      var toReturn = 0;
      if (this.tender) {
        this.tender.forms.forEach(form => {
          toReturn += form.traded_offer_to_reserve ? form.traded_offer_to_reserve : 0;
        });
      }
      return toReturn;
    },
    calculateTheoreticalReserve: function () {
      var toReturn = 0;
      if (this.tender) {
        this.tender.forms.forEach(form => {
          toReturn += form.highest_offer_to_reserve ? form.highest_offer_to_reserve : 0;
        });
      }
      return toReturn;
    },
    tenderHasStarted(tender) {
      return tenderHasStarted(tender.start_time);
    },
    tenderHasEnded(tender) {
      return tenderHasEnded(tender.end_time);
    },
    formatDate(dateString) {
      let convertedDate = new Date(dateString);
      return convertedDate.toDateString() + ", " + convertedDate.toLocaleTimeString();
    },
    ...mapActions({
      setForms: "formStore/setForms",
      downloadCSV: "formStore/downloadCSV",
      addError: "errorStore/addError"
    })
  },
  mounted: function () {
    this.setForms([]);
    this.fetchTender();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#tender-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.time-label {
  @extend .body-2;
  @extend .margin-top-05;
}

.up-on-reserve {
  @extend .body-2-bold;
  @extend .margin-top-05;
  color: $ACTIVE_EVENT_COLOR;
}

.down-on-reserve {
  @extend .body-2-bold;
  @extend .margin-top-05;
  color: $ERROR_RED_COLOR;
}
</style>
